import React, { useState } from 'react';
import './accordion.css';

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-[30px] bg-quaternaire rounded-[10px] relative z-10">
      <button
        className="w-[80vw] desktop:w-[1200px] flex justify-between items-center py-[15px] pl-[40px] pr-[20px]"
        onClick={toggle}
      >
        <span className="questions-text">{title}</span>
        <span className='questions-pictos'>{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && <div className="answers py-[15px] pl-[40px] pr-[20px] text-left bg-quaternaire rounded-[10px]">{children}</div>}
    </div>
  );
};

const accordion = ({ items }) => {
  return (
    <div className="">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title}>
          {item.content}
        </AccordionItem>
      ))}
    </div>
  );
};

export default accordion;
