import React from 'react'
import HeaderLogo from '../../assets/images/Header-logo.svg'

import './logo.css'

const Logo = () => {
  return (
    <div className='mx-[auto] w-[319px] mt-[32px] tablet:mb-[50px]  desktop:hidden'>
      <img src={HeaderLogo} alt="" />
    </div>
  )
}

export default Logo
