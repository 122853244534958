import React from 'react';
import Trait from '../../assets/images/trait.svg'
import './slider.css'

const Slider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  return (
    <div className="relative z-10 w-[80vw] desktop:w-[1200px] mx-[auto]">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`${
            index === currentSlide ? 'block' : 'hidden'
          }  flex justify-space-between items-top mt-[40px] desktop:mt-[140px]`}
        >
          <div className="flex flex-col desktop:flex-row tablet:flex-row">
            <img src={slide.image} alt={slide.title} className=" w-[320px] h-[427px] object-cover desktop:w-[798px] desktop:h-[498px] tablet:w-[398px] tablet:h-[248px] rounded-[20px]" />
            <div className="elements relative">
              <div className="desktop:w-[500px] tablet:w-[375px] tablet:pt-[0px] absolute text-theme pt-[20px] tablet:bg-secondaire-claire">
                <h2 className="text-left font-bold w-[240px] mb-4 bg-secondaire-claire pl-[10px] pt-[20px] desktop:w-[500px] desktop:pl-[0px] desktop:pt-[0px]">{slide.title}</h2>
                <div className="mt-2 text-left w-[320px] desktop:w-[500px]">
                  {slide.themes.map((theme, i) => (
                    <span
                      key={i}
                      className="uppercase thematique inline-block hover:bg-principale bg-quaternaire hover:text-quaternaire text-black px-[15px] py-[12px] rounded-[10px] mr-2 mb-2 desktop:mb-0"
                    >
                      {theme}
                    </span>
                  ))}
                </div>
                <div className="infos-theme static tablet:absolute tablet:left-[115px] desktop:left-[123px] text-left">
                  <p className="text-left projets-p text-black hidden desktop:block">{slide.description}</p>
                  <button type="button" class=" mt-[24px] text-left uppercase text-white bg-principale hover:bg-principale-sombre focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-[25px] py-[12px]">
                    <a target="blank" href={slide.link} className='flex items-center'>
                      <svg class="rtl:rotate-180  mr-[8px] w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                      </svg>
                      {slide.buttonText}
                    </a>
                  </button>
                  <div className="absolute flex items-center mt-[33px] desktop:mt-[0px] desktop:ml-[205px] gap-[20px] slider-btn bg-quaternaire rounded-[20px] px-[20px]">
                  <button aria-label="precedent"onClick={prevSlide} type="button" class="text-left uppercase px-[8px] py-[12px] flex items-center">
                    <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.70005 14L13.4 19.7C13.8 20.1 13.9917 20.5667 13.975 21.1C13.9584 21.6334 13.7667 22.1 13.4 22.5C13 22.9 12.525 23.1084 11.975 23.125C11.425 23.1417 10.95 22.95 10.55 22.55L1.40005 13.4C1.00005 13 0.800049 12.5334 0.800049 12C0.800049 11.4667 1.00005 11 1.40005 10.6L10.55 1.45002C10.95 1.05002 11.425 0.858357 11.975 0.875023C12.525 0.89169 13 1.10002 13.4 1.50002C13.7667 1.90002 13.9584 2.36669 13.975 2.90002C13.9917 3.43336 13.8 3.90002 13.4 4.30002L7.70005 10H30C30.5667 10 31.0417 10.1917 31.425 10.575C31.8084 10.9584 32 11.4334 32 12C32 12.5667 31.8084 13.0417 31.425 13.425C31.0417 13.8084 30.5667 14 30 14H7.70005Z" fill="#441922"/>
                    </svg>
                  </button>
                  <img src={Trait} alt=""className='h-[32px]'/>
                  <button aria-label="suivant" onClick={nextSlide} type="button" class="text-left uppercase px-[8px] py-[12px] flex items-center">
                    <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.3 14H2C1.43333 14 0.958333 13.8084 0.575 13.425C0.191667 13.0417 0 12.5667 0 12C0 11.4334 0.191667 10.9584 0.575 10.575C0.958333 10.1917 1.43333 10 2 10H24.3L18.6 4.30002C18.2 3.90002 18.0083 3.43336 18.025 2.90002C18.0417 2.36669 18.2333 1.90002 18.6 1.50002C19 1.10002 19.475 0.89169 20.025 0.875023C20.575 0.858357 21.05 1.05002 21.45 1.45002L30.6 10.6C30.8 10.8 30.9417 11.0167 31.025 11.25C31.1083 11.4834 31.15 11.7334 31.15 12C31.15 12.2667 31.1083 12.5167 31.025 12.75C30.9417 12.9834 30.8 13.2 30.6 13.4L21.45 22.55C21.05 22.95 20.575 23.1417 20.025 23.125C19.475 23.1084 19 22.9 18.6 22.5C18.2333 22.1 18.0417 21.6334 18.025 21.1C18.0083 20.5667 18.2 20.1 18.6 19.7L24.3 14Z"fill="#441922"/>
                    </svg>
                  </button>
                </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      ))}

    </div>
  );
};

export default Slider;