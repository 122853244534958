import React from 'react'
import './projets.css';
import Slider from '../slider/Slider';
import JapanHouse from '../../assets/images/3D-House-Mockup.webp'
import OrnareCG from '../../assets/images/Ornare-CG.webp'
import Verveine from '../../assets/images/Verveine-Mockup.webp'
import MagTEA from '../../assets/images/Mag-TEA-Mockup.webp'
import RunxAPP from '../../assets/images/Runx-App-Mockup.webp'
import OrnareWeb from '../../assets/images/Ornare-Agency-Mockup.webp'
import Sundgau from '../../assets/images/CC-Sundgau-Mockup.webp'
import WMG from '../../assets/images/WMG-Mockup.webp'
import Venture from '../../assets/images/Venture-App-BONNISSOL.webp'
import CharteOrnare from '../../assets/images/Charte-Graphique-Ornare-Agency.pdf'
import VentureApp from '../../assets/images/BONNISSOL-Jeremie-Venture-App.pdf'
import TrekReunion from '../../assets/images/Trek-Reunion-Island-Mockup.webp'
import AirbusZeroE from '../../assets/images/Airbus-Zero-E.webp'


const slides = [
  {
    image: AirbusZeroE,
    title: 'Airbus Zero Emission',
    description: 'Réalisation d\'une réflexion digitale pour le groupe Airbus afin de promouvoir leur projet de futur avion zéro émission. L\'agence a donc proposé une expérience digitale via un site internet et une application mobile ainsi qu\'une solution physique sous forme d\'escape game ludique et éducatif.',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', 'UX/UI Design', 'Figma' ],
    link:'https://www.figma.com/deck/vKG9lMgUxRX0187iHJgraw',
  },
  {
    image: Venture,
    title: 'Venture App',
    description: 'Réalisation du design d\'une application mobile fictive ayant pour but de réduire le réchauffement climatique avec des écogestes liés au sport.',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', 'UX/UI Design', 'Figma' ],
    link:VentureApp,
  },
  {
    image: TrekReunion,
    title: 'Trek Reunion Island',
    description: 'Réalisation du design d\'un site communautaire ayant pour but de promouvoir un trek sur l\'ile de la Réunion surnomé l\'ile intense. ',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', 'UX/UI Design', 'Figma' ],
    link:'https://www.figma.com/design/x3sRGdD2OBEwU0iiVYN7zb/Trek-Reunion-Island?node-id=0-1&t=Et0RnkaYnNzofXkp-1',
  },
  {
    image: WMG,
    title: 'Motion WMG',
    description: 'Création d\'un Motion Design pour Warner Music Group. L\'objectif était de réalisé une vidéo en cohérence avec l\'identité visuelle de WMG. Projet réalisé dans le cadre de mon Master 2 en Design d\'Expériences & d\'Interfaces sur le logiciel After Effects.',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', 'Motio design', 'After Effects' ],
    link:'https://www.youtube.com/watch?v=fI9GzN8iTIY',
  },
  {
    image: Sundgau,
    title: 'CC Sundgau',
    description: 'Maquettage et intégration (en collaboration avec Auctavia Communication) du site internet de la CC Sundgau.',
    buttonText: 'Découvrir le projet',
    themes: ['projet pro', 'Wordpress', 'UX/UI Design', 'Divi'],
    link:'https://cc-sundgau.fr/',
  },
  {
    image: OrnareWeb,
    title: 'Ornare Agency',
    description: 'Réalisation et développement en React et Tailwind CSS des maquettes du site internet d\'Ornare Agency.',
    buttonText: 'Découvrir le projet',
    themes: ['projet pro', 'React', 'UX/UI Design', 'Figma'],
    link:'https://jeremie-bonnissol.fr',
  },
  {
    image: RunxAPP,
    title: 'Runx App',
    description: 'Réalisation d\'une application de course à pied faite avec l\'outil no/low code Webflow.',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', 'Application', 'UX/UI Design', 'Figma'],
    link:'https://runx-app.webflow.io/',
  },
  {
    image: MagTEA,
    title: 'Magazine TEA',
    description: 'Réalisation du magazine pour le Territoire d\'Énergie Alsace.',
    buttonText: 'Découvrir le projet',
    themes: ['projet pro', 'Magazine', 'in-design', 'ipression'],
    link:'https://te.alsace/wp-content/uploads/2024/07/LE_MAGAZINE_5.pdf',
  },
  {
    image: JapanHouse,
    title: '3D Japan House',
    description: 'Création et animation d\'une maison japonaise en isométrique sur Blender.',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', '3d', 'blender', 'isometrie'],
    link:'https://www.youtube.com/watch?v=zqPv-VDGqmw',
  },
  {
    image: Verveine,
    title: 'Motion Verveine',
    description: 'Réalisation d\'une PUB en motion Design dans le cadre universitaire afin de promouvoir un objet. J\'ai choisi de mettre en avant la verveine verte du Velay spécialité altiligérienne.',
    buttonText: 'Découvrir le projet',
    themes: ['projet étudiant', 'motion design', 'after effects'],
    link:'https://www.youtube.com/watch?v=-nJ3q0kPk-8',
  },
  {
    image: OrnareCG,
    title: 'Ornare Agency',
    description: 'Réalisation de la nouvelle identité visuelle de mon entreprise Ornare Agence | Jérémie BONNISSOL.',
    buttonText: 'Découvrir le projet',
    themes: ['projet pro', 'charte graphique', 'in design'],
    link: CharteOrnare,
  },
  // Ajoutez d'autres slides au besoin
];

const Projets = () => {
  return (
    <div id="projets" className='projets bg-secondaire-claire pb-[300px] tablet:pb-[0px] relative'>
      <div className="mx-auto w-[80vw] desktop:w-[1200px]">
        <h2 className='text-left'>Découvrez mon <span>univers</span> avec mes différentes <span>créations.</span></h2>
        <p className='text-left mt-[30px] desktop:mt-[50px]'>Mon univers graphique est un savant mélange de créativité et de fonctionnalité, où chaque élément est pensé pour offrir une expérience utilisateur optimale. Je vous laisse découvrir ce dernier à travers mes différents projets.</p>
      </div>
      <div className="app">
        <Slider slides={slides} />  
      </div>
    </div>
  );
};

export default Projets
