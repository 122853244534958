import React from 'react'
import HeaderLogo from '../../assets/images/Header-logo.svg'
import './navbar.css'

const Navbar = () => {
  return (
    <div>
      <nav class="hidden desktop:block bg-white border-gray-200 dark:bg-gray-900">
        <div class="max-w-[1200px] flex flex-wrap items-center justify-between mx-auto p-4 m-12 ">
        <a href="https://jeremie-bonnissol.fr/" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={HeaderLogo} alt='Logo Ornare Agency'/>
        </a>
        <ul class="flex font-medium p-4 md:p-0">
            <li className='px-[12.5px]'>
            <a href="#services" class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-principale uppercase">Mes services</a>
            </li>
            <li className='px-[12.5px]'>
              <a href="#processus" class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-principale uppercase">Mon processus</a>
            </li>
            <li className='px-[12.5px]'>
              <a href="#projets" class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-principale uppercase">Mes projets</a>
            </li>
          </ul>
        <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <a href="mailto:jbonnissol.pro@gmail.com" class="text-white bg-principale hover:bg-principale-sombre uppercase focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Me Contacter</a>
        </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
