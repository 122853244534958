import React from 'react'
import ServiceCode from '../../assets/images/Service-Code.svg'
import ServiceLogo from '../../assets/images/Service-Logo.svg'
import ServiceDesign from '../../assets/images/Service-Design.svg'
import ServiceCharte from '../../assets/images/Service-Charte.svg'
import Forme from '../../assets/images/Forme-Jaune.svg'
import './services.css'


const data = [
    {
      id: 1,
      image: ServiceCode,
      title: 'Intégration Web',
      description: 'Je développe votre site internet à votre image.'
    },
    {
      id: 2,
      image: ServiceLogo,
      title: 'Logo',
      description: 'Je réalise votre logo afin d’avoir une image d’entreprise forte.'
    },
    {
      id: 3,
      image: ServiceDesign,
      title: 'UX / UI Design',
      description: 'Spécialiste du domaine je conçois la meilleure expérience pour vos clients.'
    },
    {
      id: 4,
      image: ServiceCharte,
      title: 'Charte Graphique',
      description: 'J\'imagine tout un univers graphique allant avec votre logo afin d’avoir une unité graphique.'
    }
  ];
  
  const Services = () => {
    return (
        <div className='services flex tablet:grid gap-[24px] w-[90vw] tablet:mx-auto tablet:w-[80vw] desktop:w-[1200px] tablet:grid-cols-2 desktop:grid-cols-4'>
            {data.map(item => (
            <div className=" relative text-left service bg-quaternaire  py-[20px] px-[20px] rounded-[20px]"key={item.id}>
                <img src={item.image} alt={item.description} />
                <h4 className='mt-[22px] text-tertiaire'>{item.title}</h4>
                <p className='mt-[15px] text-tertiaire'>{item.description}</p>
                <img className="forme" src={Forme} alt="" />
            </div>
            ))}
        </div>       
    );
  };
export default Services