import React from 'react'
import Etape1 from '../../assets/images/Etape1-IMG.svg'
import Etape2 from '../../assets/images/Etape2-IMG.svg'
import Etape3 from '../../assets/images/Etape3-IMG.svg'
import Etape4 from '../../assets/images/Etape4-IMG.svg'
import Forme1 from '../../assets/images/Forme-Etape1.svg'
import Forme2 from '../../assets/images/Forme-Etape2.svg'
import Forme3 from '../../assets/images/Forme-Etape3.svg'
import Forme4 from '../../assets/images/Forme-Etape4.svg'
import Hurdler from '../../assets/images/Hurdler.svg'

import './stages.css'


const data = [
    {
      id: 1,
      text:'Etape 1',
      image: Etape1,
      title: 'Réunion de lancement',
      description: 'Ensemble nous prenons le temps de bien définir vos besoins ainsi que vos différentes attentes.',
      forme: Forme1
    },
    {
      id: 2,
      text:'Etape 2',
      image: Etape2,
      title: 'Réflexion',
      description: 'Une fois vos objectifs fixés, je pourrai concevoir la meilleure expérience pour ce dernier.',
      forme: Forme2
    },
    {
      id: 3,
      text:'Etape 3',
      image: Etape3,
      title: 'Création',
      description: 'Ensuite, je viendrais ajouter ma touche graphique afin de donner vie à votre projet.',
      forme: Forme3
    },
    {
      id: 4,
      text:'Etape 4',
      image: Etape4,
      title: 'Livraison',
      description: 'Je vous livrerai et vous formerai ensuite pour exploiter au mieux vos nouveaux outils de communication.',
      forme: Forme4
    }
  ];
  
  const Etapes = () => {
    return (
      <div id="processus" className='w-[80vw] tablet:w-[80vw] desktop:w-[1200px] mx-auto etapes mt-[60px] mb-[130px] desktop:my-[130px]'>
      <div className="tablet:flex-row desktop:flex-row desktop:gap-[167px] tablet:justify-between desktop:justify-normal mx-auto header-etapes flex flex-col gap-[40px] items-end tablet:items-center desktop:items-center relative">
        <div className="">
          <h2 className='text-left mb-[50px] desktop:w-[564px] tablet:w-[318px]'>Ensemble nous <span>franchirons</span> les différents <span>obstacles !</span></h2>
          <p className='text-left desktop:w-[486px] tablet:w-[376px]'>En tant que Freelance je me dois de vous accompagner tout au long de votre projet.<br/> <br/> C’est ensemble que nous surmonterons tous les obstacles afin de vous offrir un projet complet et abouti.</p>
        </div>
        <div className="image w-[190px] tablet:w-[272px] z-10">
          <img src={Hurdler} alt="" />
        </div>
      </div>
        <div className='z-10 relative top-[135px] grid gap-[48px] w-[80vw] mx-auto tablet:mx-auto tablet:w-[80vw] desktop:w-[1200px] tablet:grid-cols-2 desktop:grid-cols-4'>
            {data.map(item => (
            <div className="etapes relative text-center desktop:text-left service bg-quaternaire pb-[43px] desktop:pb-[20px] px-[20px] rounded-[20px]"key={item.id}>
                <h3 className='relative z-10 bg-principale text-quaternaire inline-block py-[5px] px-[15px] uppercase mb-[30px] mt-[-20px] '>{item.text}</h3>
                <div className="title flex gap-[7px] items-center desktop:items-start  desktop:flex-col desktop:items-left">
                  <img src={item.image} alt={item.description} />
                  <h4 className='w-60vw desktop:w-full desktop:mt-[22px] text-tertiaire text-left'>{item.title}</h4>
                </div>
                <p className='text-left mt-[20px] text-tertiaire'>{item.description}</p>
                <img className="forme-b" src={item.forme} alt="" />
            </div>
            ))}
        </div> 
        </div>      
    );
  };

  export default Etapes