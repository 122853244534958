import React from 'react'
import RS1 from '../../assets/images/RS-1.svg'
import RS2 from '../../assets/images/RS-2.svg'
import RS3 from '../../assets/images/RS-3.svg'

import CharteOrnare from '../../assets/images/Charte-Graphique-Ornare-Agency.pdf'
import VentureApp from '../../assets/images/BONNISSOL-Jeremie-Venture-App.pdf'

import FooterLogo from '../../assets/images/Footer-Logo+Marque-Web.svg'
import './footer.css';

const Footer = () => {
  return (
    <div className="footer-principale">
      <div className="bg-secondaire-claire py-[25px]">
        <div className='social flex flex-col desktop:flex-row w-[80vw] desktop:w-[1200px] mx-[auto] gap-[24px] items-center'>
            <h4>Retrouvez moi sur mes différents Réseaux Sociaux :</h4>
            <div className="rsx flex gap-[24px] items-center">
              <a href="https://www.pinterest.fr/jbonnissol/">
                <img src={RS1} alt="icone Pinterest" />
              </a>
              <a href="https://www.behance.net/jeremieboneb30">
                <img src={RS2} alt="icone Pinterest" />
              </a>
              <a href="https://dribbble.com/Jeremie_Dsgn">
                <img src={RS3} alt="icone Pinterest" />
              </a>
            </div>
        </div>
      </div>
      <div className="bg-tertiaire footer py-[50px]">
        <div className="flex-col  flex desktop:flex-row w-[80vw] desktop:w-[1200px] mx-[auto] items-bottom justify-between">
          <div className="col-1 mb-[44px] desktop:mb-[0px] text-left">
            <img src={FooterLogo} alt="" className='mb-[20px]'/>
            <h6 className='mb-[10px]'>Ornare Agency | Auto Entreprise</h6>
            <p><span>SIRET</span> : 91901676600017 <br/>
            <span>RCS</span> : 919 016 766 LE PUY-EN-VELAY</p>
          </div>
          <div className="col-2 flex-col flex  desktop:flex-row items-bottom justify-between desktop:w-[800px] w-[80vw]">
            <div className="competences mb-[44px] desktop:mb-[0px]">
                <h5 className='text-left text-secondaire-claire mb-[15px]'>Mes Services</h5>
                <div className="list list-disc text-left">
                  <ul>
                    <li>
                      <a href="#services">Intégration Web</a>
                    </li>
                  
                    <li>
                      <a href="#services">Charte Graphique</a>
                    </li>
                    <li>
                      <a href="#services">UX/UI Design</a>
                    </li>
                    <li>
                      <a href="#services">Logotype </a>
                    </li>
                  </ul>
                </div>
            </div>
            <div className="projects mb-[44px] desktop:mb-[0px]">
                <h5 className='text-left text-secondaire-claire mb-[15px]'>Mes Projets</h5>
                <div className="list list-disc text-left">
                  <ul>
                    <li>
                      <a href={VentureApp}>Venture App</a>
                    </li>
                    <li>
                      <a href={CharteOrnare}>Charte Graphique Ornare Agency</a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/watch?v=fI9GzN8iTIY" target='blank'>Warner Music Group</a>
                    </li>
                    <li>
                      <a href="https://runx-app.webflow.io/" target='blank'>Runx App</a>
                    </li>
                    <li>
                      <a href="https://te.alsace/wp-content/uploads/2024/07/LE_MAGAZINE_5.pdf" target='blank'>Magazine TEA</a>
                    </li>
                  </ul>
                </div>
            </div>
            <div className=" flex flex-col items-start contact-footer mb-[44px] desktop:mb-[0px]">
              <h5 className='text-left text-secondaire-claire mb-[15px]'>Me Contacter</h5>
              <a href="mailto:jbonnissol.pro@gmail.com" className='px-[20px] py-[12px]'> jbonnissol.pro@gmail.com</a>
              <h5 className='mt-[25px] text-left text-secondaire-claire mb-[15px]'>À Propos</h5>
              <div className="list list-disc text-left">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/in/jebonnisso/" target='blank'>Voir mon <span>LinkedIN</span></a>
                  </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
