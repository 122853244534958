import React from 'react'
import './questions.css';
import Accordion from '../accordion/Accordion';

const items = [
    {
      title: 'Quels sont vos tarifs ?',
      content: 'Il est difficile de donner un tarif précis car chaque projet est unique et c\'est ensemble que nous définirons clairement vos besoins. Ensuite je vous proposerai un devis à partir de mon tarif journalier qui est de 300€/jour. En moyenne, pour un site vitrine contenant environ 5 pages je propose un tarif d\'environ 2 500€. Ce tarif comprend le maquettage, l\'intégration du site avec Webflow ainsi que la mise en ligne de ce dernier avec une formation afin de vous laisser le site dans les meilleurs conditions. ',
    },
    {
      title: 'Quelle est votre disponibilité ?',
      content: 'Je suis actuellement disponible. Vous avez simplement à me contacter afin de prendre un RDV et de commencer votre projet ensemble',
    },
    {
      title: 'Quelles sont vos conditions de paiements ?',
      content: 'Je fonctionne de sorte à ce que vous versiez 50% au démarrage du projet en acompte et 50% à la livraison de celui-ci. Si vous ne respectez pas les paiements le projet ne démarrera simplement pas.',
    },
    {
      title: 'Quels sont vos services ?',
      content: 'Je suis capable de gérer votre projet de A à Z en passant de la réflexion de votre logotype, la réalisation de votre charte graphique en passant par la réflexion de tout le parcours utilisateur ainsi que l intégration.',
    },
  ];

const Questions = () => {
  return (
    <div className='section-questions bg-principale-sombre mt-[0px] h-[800px]'>
        <div className="questions items-center justify-between w-[80vw] desktop:w-[1200px] mx-[auto] pt-[100px]">
            <h2 className='h2 text-tertiaire mx-[auto] mb-[40px]'>Vous avez des <span>questions ?</span></h2>
            <Accordion items={items}/>
        </div>
    </div>
  )
}

export default Questions
