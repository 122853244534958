import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Auctavia from '../../assets/images/Auctavia-Logo.svg'
import NewDynastie from '../../assets/images/New-Dynastie.svg'
import OreeVoltaire from '../../assets/images/Oree-Voltaire.svg'
import Runx from '../../assets/images/Runx.svg'
import LoremIpsum from '../../assets/images/Lorem-Ipsum.svg'
import './logocarousel.css'

const Logocarousel = () => {

    const settings = {
        infinite: true,
        speed: 5000,
        slidesToShow:11,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 500,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 1280, // 1024px et moins
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 768, // 640px et moins
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      };

  return (
    <div className="overflow-x-hidden	bg-principale-sombre py-[16px] border-y-[3px] border-principale-claire">
        <Slider {...settings}>
            <li className='after px-[10px]'>
              <img src={Auctavia} alt="Logo 1" />
            </li>
            <li className='after px-[10px]'>
              <img src={NewDynastie} alt="Logo 2" />
            </li>
            <li className='after px-[10px]'>
              <img src={OreeVoltaire} alt="Logo 3" />
            </li>
            <li className='after px-[10px]'>
              <img src={Runx} alt="Logo 3" />
            </li>
            <li className='after px-[10px]'>
              <img src={LoremIpsum} alt="Logo 3" />
            </li>
            <li className='after px-[10px]'>
              <img src={Auctavia} alt="Logo 1" />
            </li>
            <li className='after px-[10px]'>
              <img src={NewDynastie} alt="Logo 2" />
            </li>
            <li className='after px-[10px]'>
              <img src={OreeVoltaire} alt="Logo 3" />
            </li>
            <li className='after px-[10px]'>
              <img src={Runx} alt="Logo 3" />
            </li>
            <li className='after px-[10px]'>
              <img src={LoremIpsum} alt="Logo 3" />
            </li>
        </Slider>
        </div>
  )
}

export default Logocarousel
