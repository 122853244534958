import React from 'react'
import CV from '../../assets/images/CV-BONNISSOL-Jeremie-Web-2024.pdf'

import './hero-section.css'

const HeroSection = () => {
  return (
    <div>
      <div id='services' className="section bg-right-top bg-contain">
        <div className="hero-section w-[80vw] mx-auto desktop:w-[1200px]">
          <div className="hero-left text-left ml-0 self-start">
            <h1 className='text-left leading-none pt-[40px] tablet:pt-[0px] w-[60vw] tablet:w-[65vw] desktop:w-[456px]'>Soyez <span>toujours les</span> premiers</h1>
            <p className='mt-[20px] w-[40vw] tablet:mt-[25px] desktop:mt-[50px] tablet:w-[40vw] desktop:w-[384px] text-left'>Hello, je m'appelle Jérémie, <span className='bold'>Directeur Artistique </span>Junior en <span className='bold'>freelance. </span> <span className='hidden view tablet:inline-block desktop:inline-block'>  <br/>Sur ce portfolio vous découvrirez mon univers avec mes créations <span className='bold'>professionnelles</span> et <span className='bold'>étudiantes.</span></span></p>
            <a href={CV} target='blank' type="button" className="mt-[30px] tablet:mt-[25px] desktop:mt-[50px] text-left uppercase text-white bg-principale hover:bg-principale-sombre rounded-[10px] font-bold border-[5px] border-white text-sm px-[25px] py-[12px] inline-flex items-center">
              <svg className="rtl:rotate-180 mr-[8px] w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
              voir mon cv
          </a>
          </div>
          <div className="hero-right">

          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
