import React from 'react'
import Athlete from '../../assets/images/Athlete-Arrivee.svg'
import './contact.css'

const Contact = () => {
  return (
    <div id="contact" className="contact mt-[70px] mb-[100px] tablet:my-[250px] desktop:my-[250px]">
      <div className='w-[80vw] tablet:w-[80vw] desktop:w-[1200px] mx-auto tablet:flex-row desktop:flex-row justify-between mx-auto flex flex-col gap-[40px] tablet:items-center desktop:items-center'>
          <div className="desktop:w-[564px] w-[80vw] items-start text-left  ">
            <h2 className='text-left mb-[50px] desktop:w-[564px] tablet:w-[318px]'>Vous souhaitez <span>franchir</span> la ligne en <span>premier !</span></h2>
            <p className='text-left mt-[30px] desktop:mt-[50px]'>Vous avez une idée de projet, n’hésitez pas à me contacter pour en discuter ensemble afin de mieux connaître vos besoins. </p>
            <div className="ml-[0px] inline-flex desktop:flex text-left flex-col tablet:flex-row gap-[24px] mt-[33px]">
              <a href='mailto:jbonnissol.pro@gmail.com' target='blank' type="button" className= "tablet:mt-[25px] desktop:mt-[50px] uppercase text-white bg-principale hover:bg-principale-sombre rounded-[10px] font-bold border-white text-sm px-[25px] py-[12px] inline-flex items-center">
                me contacter
                <svg className="rtl:rotate-180 mr-[8px] w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
              </a>
              <a href='tel:0670995867' target='blank' type="button" className=" tablet:mt-[25px] desktop:mt-[50px] uppercase text-white bg-principale hover:bg-principale-sombre rounded-[10px] font-bold border-white text-sm px-[25px] py-[12px] inline-flex items-center">
                me téléphoner
                <svg className="rtl:rotate-180 mr-[8px] w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
            </a>
            </div>
          </div>
          <div className="hidden block tablet:block tablet:w-[320px] desktop:w-[583px] z-10">
            <img src={Athlete} alt="" />
          </div>
      </div>
    </div>
  )
}

export default Contact
